dsf.mix(true, dsf.config, {
    webRoot: {
      //默认请求路径根
      default: "/dsf5/",
      //dsfa4.0
      dsfa: "/dsfa/",
      teas: "/dsfa/",
      exam: "/dsfa/"
    },
    file: {
      preViewServer: "/dsfa/"
    },
    assetsDir: "./static/",
    designer: {
      scssDir: process.env.NODE_ENV === "production" ? "/dsf5/static/" : "./static/",
    },
    //当前运行类型：project--项目
    runType: "project",
    //默认皮肤
    setting_public_default_theme: "red-theme",
    //默认字体大小
    setting_public_default_size: "normal",
    //默认顶部导航栏是否为窄条
    setting_public_default_header_mini: false,
    //页面右下角是否显示设计器跳转图标
    setting_public_gotoIcon: "1",
    //登录成功后进入页面地址
    setting_public_login_success_to: "./index.html",
    //登陆成功后跳转页面
    setting_public_mobile_login_success_to: "./mobile-index.html",
    //登陆页地址
    setting_public_pc_login_page: "./page.html#/pc/login",
    setting_public_mobile_login_page: "./mobile-index.html#/mobile/login",
    //项目logo图片地址
    setting_public_logo_image: "",
    //项目名称
    setting_public_logo_title: "",
    //默认用户头像
    setting_public_user_default_header: "./static/img/platform/defaultHeader.png",
    //菜单请求接口
    setting_public_home_menu_src: "/menu/getTree",
    //退出登录接口
    setting_public_loginout: "CommonLogin/loginOut",
    //默认预览
    setting_public_file_host: "/",
    // 流程提示弹窗倒计时间，默认为3
    setting_flow_count_down_time: 3,
  },
  window.$$serverConfig ? window.$$serverConfig : {},
  window.$$config ? window.$$config : {}
);
window.$$webRoot = dsf.config.webRoot;