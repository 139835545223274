
import Vuex from "vuex";
import mutations from './mutation';
import actions from './actions';

//注入平台层vuex模块
// import _ncStoreModules from '_nc/store';
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    route: {}
  },
  mutations,
  actions,
  modules: {
    ...$platformStore,
    // ..._ncStoreModules
  }
});